




































import { mapGetters } from 'vuex';
import dataSettingApis from '@/apis/data-setting.apis';
import commonUtils from '@/common/utils/common.utils';
import Onboarding from './Onboarding';
import SettingsTable from './table.vue';
import docsUrl from '@common/configs/docsUrl';
import { checkModuleRole, RoleModuleKey } from '@/common/services/singleSpa.service';

export default {
    name: 'EventSettings',

    data() {
        const moreOptions = [
            {
                name: this.$t('common.button_edit'),
                code: 'edit',
                roleKey: 'ptxEventUpdate'
            }
        ].filter(item => checkModuleRole(item.roleKey as RoleModuleKey));
        const tableHeader = [
            {
                code: 'name',
                sortCode: 'showName',
                name: this.$t('data_settings.list_name'),
                dataType: 'string',
                supportSortable: true,
                span: 8
            },
            {
                code: 'source',
                sortCode: 'showSource',
                name: this.$t('data_settings.list_source'),
                dataType: 'string',
                supportSortable: true,
                span: 4
            },
            {
                code: 'description',
                sortCode: 'showDescription',
                name: this.$t('common.description'),
                dataType: 'string',
                canEdit: true,
                supportSortable: true,
                span: 12
            }
        ];
        const sourceOptions = [
            {
                code: 'code',
                name: this.$t('data_settings.data_source_code')
            },
            {
                code: 'experience',
                name: this.$t('data_settings.data_experience')
            },
            {
                code: 'setting',
                name: this.$t('data_settings.data_source_setting')
            }
        ];
        const minLen = 6;
        return {
            moreOptions,
            tableHeader,
            sourceOptions,
            tableBody: [],
            currentSortIndex: 0,
            currentSortType: 'desc',
            joinString: '_tmp', //备份字段连接字符串
            loading: true,

            paginationStates: {
                pageSize: minLen,
                minLen,
                currentPage: 1
            },

            docUrl: docsUrl.eventSetting
        };
    },

    computed: {
        ...mapGetters('main', ['profileInfo', 'userInfo']),

        canEditList() {
            return this.tableHeader.filter(item => item.canEdit);
        },

        currentRouteIsMe() {
            return this.$route.name === 'EventSettings';
        },

        iconName() {
            return this.userInfo.area.toLocaleUpperCase() === 'EN'
                ? 'icon-event-en'
                : this.userInfo.area.toLocaleUpperCase() === 'ZH'
                ? 'icon-event-cn'
                : 'icon-event';
        }
    },

    created() {
        this.fetchEvents();
    },

    methods: {
        async fetchEvents() {
            const [err, res] = await commonUtils.awaitWrap(
                dataSettingApis.getEvents(null, {
                    where: {
                        sid: this.profileInfo.sid
                    }
                })
            );
            if (err) {
                this.currentRouteIsMe && console.log('get events failed.');
            } else {
                this.tableBody = res.map(event => {
                    event.rowEditing = false;
                    Object.keys(event).forEach(key => {
                        const eventInfo = this.tableHeader.find(item => item.code === key);
                        if (eventInfo) {
                            event[eventInfo.sortCode] = this.getCellVal(key, event[key]);
                        }
                    });

                    // 备份可编辑项数据
                    Object.keys(event).forEach(key => {
                        const canEdit = this.canEditList.some(item => item.code === key);
                        canEdit && (event[`${key}${this.joinString}`] = event[key]);
                    });
                    return event;
                });
            }
            this.loading = false;
        },

        async updateEvent({ id, name, displayName, source, description }) {
            const [err, res] = await commonUtils.awaitWrap(
                dataSettingApis.updateEvent(null, {
                    data: {
                        name,
                        displayName,
                        source,
                        description
                    },
                    where: {
                        name,
                        sid: this.profileInfo.sid
                    }
                })
            );
            err && this.currentRouteIsMe && this.$message.error(this.$t('error.update_event'));
        },

        getCellVal(code, value) {
            if (code === 'type') {
                const info = this.typeOptions.find(item => item.code === value.toLocaleUpperCase());
                return (info && info.name) || value;
            } else if (code === 'source') {
                return (value || [])
                    .map(i => {
                        var sourceInfo = this.sourceOptions.find(item => item.code === i);
                        return (sourceInfo && sourceInfo.name) || i;
                    })
                    .join();
            }
            return value;
        }
    },

    components: {
        Onboarding,
        SettingsTable
    }
};
